// components/Header.js

import React from 'react';

function Header1() {
  return (
    <header className="header1">
      <div className="header-content1">
        <h1>21 Short stories for Kids and Teens</h1>
      </div>
    </header>
  );
}

export default Header1;
