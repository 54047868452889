// components/Footer.js

import React from 'react';

function Footer() {
  return (
    <div>
    <footer className="footer">
      <p>&copy; {new Date().getFullYear()} All Rights Reserved.</p>
    </footer>
    </div>
  );
}

export default Footer;
