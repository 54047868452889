import React from 'react';
import BookCover2 from './BookCover2'; // Create a BookCover component to display the book cover image

function BookInfo2() {
  return (
    <section className="book-info2" id="about">
      <div className="book-description2">
        <p>Explore your inner self on a transformative journey with "Inner Peace: The Art of Meditation." This insightful e-book reveals the keys to peacefulness and self-discovery as it leads you through the profound practice of meditation. Get lost in these pages and discover how to live a more calm, focused life.</p>
        <div className="book-content2">
        <div className="book-details2">
          <div className="author-info2" id="author">
            <h2>By : Jeepra</h2>
            <button className="button2" ><a href="https://reddyvariyashwanth.gumroad.com/l/xesom" id="websiteLink"> Buy Now</a></button>
          </div>
        </div>
        <div className="book-cover-container2">
          <BookCover2 />
        </div>
      </div>
      </div>
      
    </section>
  );
}

export default BookInfo2;

