// components/Header.js

import React from 'react';

function Header2() {
  return (
    <header className="header2">
      <div className="header-content2">
        <h1>Inner Peace : The Art of Meditation</h1>
      </div>
    </header>
  );
}

export default Header2;