import React from 'react';

function BookCover2() {
  return (
    <img
      src="book2.jpg" // Replace with the path to your book cover image
      alt="Book Cover"
      className="book-cover2"
    />
  );
}

export default BookCover2;