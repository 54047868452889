import React from 'react';
import BookCover1 from './BookCover1'; // Create a BookCover component to display the book cover image

function BookInfo1() {
  return (
    <section className="book-info1" id="about">
      <div className="book-description1">
        <p>"Discover the enchanting world of '21 Short Stories for Kids and Teens.' Each tale sparks imagination, adventure, and life lessons. From magical quests to heartwarming friendships, this collection is a must-read for young minds. Join us for a journey through stories that will ignite your child's love for reading."</p>
        <div className="book-content1">
        <div className="book-details1">
          <div className="author-info" id="author">
            <h2>By : Jeepra</h2>
            <button className="button1" ><a href="https://reddyvariyashwanth.gumroad.com/l/qetjq" id="websiteLink"> Buy Now</a></button>
          </div>
        </div>
        <div className="book-cover-container1">
          <BookCover1 />
        </div>
      </div>
      </div>
      
    </section>
  );
}

export default BookInfo1;

