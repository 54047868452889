// App.js

import React from 'react';
import './App.css';
import Header1 from './components/Header1';
import BookInfo1 from './components/BookInfo1';
import Header2 from './components/Header2';
import BookInfo2 from './components/BookInfo2';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
    <section className="book2" id="book2">
      <Header2 />
      <main>
        <BookInfo2 />
      </main>
      <Footer />
      </section>
      <section className="book1" id="book1">
      <Header1 />
      <main>
        <BookInfo1 />
      </main>
      <Footer />
      </section>
    </div>
  );
}

export default App;
