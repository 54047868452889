import React from 'react';

function BookCover1() {
  return (
    <img
      src="book1.png" // Replace with the path to your book cover image
      alt="Book Cover"
      className="book-cover1"
    />
  );
}

export default BookCover1;
